nav {
  @include box__full;
  position: relative;
  z-index: 10; }

.nav-top {
  position: fixed;
  top: 10vh;
  // right: 40%
  width: 40%;
  height: auto;
  margin: 0 auto;
  padding-right: 7%;
  opacity: .6;
  border-right: 5px solid #f1f1f1;
  background: rgba(#f1f1f1, .3);
  transform-origin: left;
  @include b-rad-trim__right-10;
  transform: translateX(-75%);
  @include trans-0;

  &:hover {
    opacity: 1;
    transform: translateX(0);
    background: rgba(#888, .8); }

  li {
    width: 100%;
    height: auto;
    list-style-type: none;

    a {
      display: flex;
      margin: 1vh 0;
      text-decoration: none;
      @include clr__combo--light-dark;
      @include b-rad__right-10;
      @include font__menu-media;

      &:hover {
        @include clr__combo--dark-light;
        @include b-rad-trim__right-10; } } }

  @media screen and (min-width: 900px) {
    top: 10vh;
    width: 20%;
    padding-right: 3vw; } }
