// -------------------->>
//   CUSTOM MIXINS
// -------------------->>

@mixin body-base__dark {
  @include box__full;
  @include clr__combo--dark-light;
  @include font__fam--primary; }

@mixin btn-hover__light-dark {
  @include clr__combo--light-dark;
  &:hover {
    @include clr__combo--dark-light; } }
