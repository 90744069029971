.panel-group {
  @include box__full;
  @media screen and (min-width: 900px) {
    @include box__half; } }

.panel .panel-default {
 }  // +box__full

.panel-heading {
  @include box__full;
 }  // border-radius: 10px

.panel-title {
  @include box__config(100%, 0 auto, 1vh 0 0, 0);
  text-align: center;
  @include clr__combo--dark-light;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 0;
    text-decoration: none;
    // border-radius: 10px
    @include pic__border-shadow--light;
    @include btn-hover__light-dark; } }

.panel-body {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include clr__combo--dark-light; }

.contact-list {
  @include box__full;
  li {
    @include box__full;
    list-style: none;
    text-align: center; } }

// Links to YouTube & Other Pages
.mcc-links {
  @include box__config(90%, auto, 4vh auto, 2vh 0);
  @media screen and (min-width: 900px) {
    @include box__config(50%, auto, 4vh auto, 2vh 0); }

  a {
    @include box__three-fourth;
    @include font__link;
    text-align: center;
    h2 {
      padding: 2vh 0;
      // border-radius: 10px
      border: 1px solid #f1f1f1;
      @include border__shadow--trim;
      @include trans-0;
      @include clr__combo--light-dark;

      &:hover {
        @include clr__combo--dark-light; } } }

  p {
    @include box__config(100%, auto, 0 auto 5vh, 5px 0); } }
