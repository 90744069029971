.web-wrap {
  @include grid__media--lg;
  padding-bottom: 10vh; }

.web-box {
  grid-column: span 1;
  @include box__config(90%, auto, 0 auto 5vh, 0);
  border-bottom: 1px solid #f1f1f1;
  img {
    @include box__full; } }

.web-text {
  grid-column: span 1;
  margin: 0;
  h2 {
    text-align: center;
    margin-bottom: 5vh; }

  @media screen and (min-width: 900px) {
    grid-column: span 3;
    @include box__config(80%, auto, 0 auto, 20px); } }
