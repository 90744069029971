/*! ====================
Web Designer: BigMic69
Webpage: MCCGraphics.info
Styling: Sass
==================== */
@import url("https://fonts.googleapis.com/css?family=Droid+Serif|Playfair+Display|Ubuntu");
@import "toolbox/colors";
@import "toolbox/fonts";
@import "toolbox/mixins";
@import "toolbox/modules";
@import "partials/base";
@import "partials/nav";
@import "partials/header";
@import "partials/intro";
@import "partials/web";
@import "partials/designs";
@import "partials/gallery";
@import "partials/contact";