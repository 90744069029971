// -------------------->>
//   BOX CONTAINERS
// -------------------->>

@mixin box__full--cush {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 2%; }

@mixin box__full {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0; }

@mixin box__three-fourth {
  width: 75%;
  height: auto;
  margin: 0 auto;
  padding: 0; }

@mixin box__half {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 0; }

@mixin box__quarter {
  width: 25%;
  height: auto;
  margin: 0 auto;
  padding: 0; }

@mixin box__fixed--bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0; }

@mixin box__config($width,$height,$margin,$padding) {
  width: $width;
  height: $height;
  margin: $margin;
  padding: $padding; }




// -------------------->>
//   CSS GRID
// -------------------->>

@mixin grid--small {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 10px; }

@mixin grid__media--lg {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 10px;
  // margin-bottom: 30px
  // @media (min-width: 600px)
  //// grid-template-columns: 1fr 1fr
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr); } }





// -------------------->>
//   FLEXBOX
// -------------------->>

@mixin column__flex {
  display: flex;
  flex-direction: column;
  margin-bottom: 6vh;
  padding: 10px 5px; }

@mixin flex__menu--a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; }




// -------------------->>
//   BACKGROUNDS
// -------------------->>

@mixin bg__media {
  min-height: 30vh;
  @media (min-width: 600px) {
    min-height: 40vh; } }

@mixin bg__img--cover($source) {
  background-image: url($source);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

@mixin bg__img--contain($source) {
  background-image: url($source);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

@mixin bg__img--width($source) {
  background-image: url($source);
  background-size: 100vw auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

@mixin bg__img--height($source) {
  background-image: url($source);
  background-size: auto 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed; }





// -------------------->>
//   MARGINS/PADDINGS
// -------------------->>

@mixin nocush {
  margin: 0;
  padding: 0; }

@mixin margin-bottom__five-vh {
  margin-bottom: 5vh; }

@mixin margin-bottom__ten-vh {
  margin-bottom: 10vh; }

@mixin padding-bottom__five-vh {
  padding-bottom: 5vh; }

@mixin padding-bottom__ten-vh {
  padding-bottom: 10vh; }

@mixin padding__box {
  padding: 5vh 0; }

@mixin padding__btn {
  padding: 5px 10px; }




// -------------------->>
//   HEADINGS
// -------------------->>

@mixin title__heading--one {
  // margin-bottom: 1vh
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: .1rem;
  @media (min-width: 600px) {
    font-size: 1.8rem; }
  @media (min-width: 900px) {
    // margin-bottom: 3vh
    font-size: 2.2rem; } }

@mixin title__heading--two {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: .08rem;
  @media (min-width: 600px) {
    font-size: 1.6rem; }
  @media (min-width: 900px) {
    font-size: 2rem; } }

@mixin title__heading--three {
  padding: 8px;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: .1rem;
  @media (min-width: 600px) {
    font-size: 1.5rem; }
  @media (min-width: 900px) {
    font-size: 1.5rem; } }



// -------------------->>
//   TEXT
// -------------------->>

@mixin font__fam--primary {
  font-family: 'Ubuntu', Cormorant, Merriweather; }

@mixin font__link {
  font-size: 1.2rem;
  text-decoration: none;
  @media screen and (min-width: 900px) {
    font-size: 1.6rem; } }

@mixin font__menu-media {
  padding: 1.6vh 2vw;
  font-size: 1.2rem;
  @media screen and (min-width: 900px) {
    font-size: 1.6rem; } }

@mixin paragraph__media {
  // padding: 0 20px 10px
  font-size: 1rem;
  line-height: 1.8rem;
  @media (min-width: 600px) {
    // padding: 20px 30px
    font-size: 1.2rem; }
  @media (min-width: 900px) {
    font-size: 1.4rem; } }

@mixin font-size__list--media {
  padding: 10px 10px 10px 5vw;
  font-size: 1rem;
  line-height: 1.6rem;
  @media (min-width: 600px) {
    font-size: 1.2rem; }
  @media (min-width: 900px) {
    font-size: 1.4rem; } }

@mixin text__align--media {
  text-align: center;
  @media (min-width: 900px) {
    padding: 10px 20px;
    text-align: left; } }





// -------------------->>
//   COLOR PALLET
// -------------------->>

@mixin clr__combo--dark-light {
  background: $clr__dark;
  color: $clr__light; }

@mixin clr__combo--light-dark {
  background: $clr__light;
  color: $clr__dark; }

@mixin clr__combo--grey {
  background: #8b8b8b;
  color: $clr__dark; }

@mixin clr__combo--custom {
  background: $clr__light;
  color: $clr__trim; }

@mixin clr__combo--custom-alt {
  background: $clr__trim;
  color: $clr__light; }





// -------------------->>
//   BORDERS/SHADOW
// -------------------->>
@mixin pic__border-shadow--dark {
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(#353535, .6);
  border-top: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1; }

@mixin pic__border-shadow--light {
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(#f1f1f1, .6);
  border-top: 1px solid #353535;
  border-left: 1px solid #353535; }

@mixin border__shadow--trim {
  border: .5px solid $clr__dark;
  border-radius: 10px;
  box-shadow: 1px 2px rgba($clr__trim,.6); }

@mixin border__shadow--dark {
  border: .75px solid $clr__dark;
  box-shadow: 3px 2px 2px rgba($clr__dark,.4); }

@mixin border__shadow--light {
  border: .5px solid $clr__light;
  border-radius: 10px;
  box-shadow: 3px 2px 2px rgba($clr__light,.4); }

@mixin box__border-shadow--corner-bo-ri {
  border-bottom: 1px solid $clr__dark;
  border-right: 1px solid $clr__dark;
  box-shadow: 3px 2px 2px rgba($clr__dark,.4); }

@mixin border-bottom__color--dark {
  border-bottom: 1px solid $clr__dark; }

@mixin border-bottom__color--trim {
  border-bottom: 1px solid $clr__trim; }

@mixin shadow__text--dark {
  text-shadow: .5px .3px $clr__dark; }

@mixin shadow__text--light {
  text-shadow: .5px .3px $clr__light; }

@mixin shadow__text--trim {
  text-shadow: .5px .3px $clr__trim; }

@mixin b-rad__right-10 {
  border-radius: 0 10px 10px 0; }

@mixin b-rad-trim__right-10 {
  border-radius: 0 10px 10px 0;
  border-right: .5vw solid #ef5425; }

// -------------------->>
//   TRANSITIONS
// -------------------->>
@mixin trans-0 {
  transition: all .3s ease-in-out .1s; }

@mixin trans-1 {
  transition: all .5s ease-in-out .1s; }

@mixin trans-2 {
  transition: all 2s ease-in-out .1s; }

@mixin trans-3 {
  transition: all 3s ease-in-out .1s; }




// -------------------->>
//   BEM MIXINS
// -------------------->>
@mixin element($element) {
  &__#{$element} {
    @content; } }

@mixin modifier($modifier) {
  &--#{$modifier} {
    @content; } }
