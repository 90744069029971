#intro {
  // display: block
  @include grid--small;
  grid-auto-rows: auto;

  p {
    @include box__config(100%, auto, 0 auto 5vh, 5px); }

  a.contact-btn {
    @include box__config(80%, auto, 5vh auto 5vh, 3vh 6vw);
    display: block;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #353535;
    @include clr__combo--dark-light;
    @include font__link;
    @include trans-0;
    @include border__shadow--dark;
    &:hover {
      @include clr__combo--light-dark; }

    @media screen and (min-width: 900px) {
      @include box__config(40%, auto, 5vh auto 5vh, 3vh 6vw); } } }
