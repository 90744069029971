*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 16px;
  line-height: 1.5rem;
  scroll-behavior: smooth; }

body {
  @include body-base__dark; }

#pageWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  @include clr__combo--dark-light; }

section {
  width: 90%;
  min-height: 50vh;
  margin: 0 auto 5vh;
  padding: 3vh 5% 0;

  &:nth-child(even) {
    color: #353535;
    background: #e3e3e3; }

  &:nth-child(6) {
    min-height: 100vh; }

  @media screen and (min-width: 900px) {
    min-height: 100vh; } }

h1 {
  margin-bottom: 3vh;
  padding-left: 4vw;
  @include title__heading--one; }

h2 {
  @include title__heading--two; }

p {
  @include box__config(100%, auto, 0, 10px);
  @include paragraph__media;
  text-align: center; }

.img-box {
  @include box__config(90%, auto, 10vh auto 5vh, 0);

  img {
    @include box__full; } }

#iframeWrap {
  @include box__full;
  @media screen and (min-width: 900px) {
    width: 100%;
    height: 60vh;
    margin: 15vh auto;
    padding: 0; } }

iframe {
  width: 100%;
  height: 60vh; }

footer {
  @include box__config(100%, auto, 0 auto, 2vh 0);
  text-align: center; }
