#gallery {
  @include bg__img--cover('../images/box-01-1400px.webp'); }

.js-slideshow {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 3vh;
  padding-bottom: 10vh;
  h1 {
    color: #f1f1f1; }
  // h2
  //   +box__config(90%, auto, 3vh auto, 2vh 0)
  //   text-align: center
 }  //   +clr__combo--dark-light


.main-img {
  @include box__full;
  // width: 100%
  border: 1px solid #ef5425;
  img {
    @include box__full; }
  @media (min-width: 900px) {
    // +box__half
    @include box__config(600px, auto, 0 auto, 0); } }

.imgs {
  grid-column: span 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2px;
  max-height: 30vh;
  overflow-y: scroll;
  border: 1px solid #ef5425;
  img {
    grid-column: span 1;
    @include box__full;
    @include trans-0;
    &:hover {
      border: 1px solid #f8f8f8; } }
  @media (min-width: 900px) {
    max-height: 40vh; } }


@keyframes FadeIn {
  to {
    opacity: 1; } }

.fade-in {
  opacity: 0;
  animation: FadeIn 0.5s ease-in 1 forwards; }
